import store from "../index";
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export function showLoader(){
    store.dispatch({type : SHOW_LOADER , payload: {}});
}

export function hideLoader(){
    store.dispatch({type : HIDE_LOADER , payload: {}});
}
