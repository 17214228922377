import { combineReducers } from '@reduxjs/toolkit';
import DEFAULT_LOADER_STATE from './reducers/loader.reducer';
import DEFAULT_USER_STATE from './reducers/user.reducer';
import DEFAULT_STAFF_STATE from './reducers/staff.reducer';
import DEFAULT_AGENCY_STATE from './reducers/agency.reducer';
import DEFAULT_CHAT_STATE from './reducers/chat.reducer';
const reducers = combineReducers({
    loader : DEFAULT_LOADER_STATE,
    user: DEFAULT_USER_STATE,
    staff: DEFAULT_STAFF_STATE,
    agency: DEFAULT_AGENCY_STATE,
    chat: DEFAULT_CHAT_STATE,
});

export default reducers;
