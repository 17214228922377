import { SHOW_LOADER, HIDE_LOADER, TOGGLE_LOADER } from '../action/loader.actions';

export interface Loader {
    show : boolean
}

export const DEFAULT_LOADER_STATE: Loader = {
    show: false,
};

export default function reducer(state = DEFAULT_LOADER_STATE, action: any) {

    switch (action.type) {
        case SHOW_LOADER:
            return { ...state, show: true };

        case HIDE_LOADER:
            return { ...state, show: false };

        case TOGGLE_LOADER:
            return { ...state, show: !state.show};

        default:
            return state;
    }
}