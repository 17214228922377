
import {USER_GET_INFO } from '../action/user.actions';

export interface LoginUser {
    userInfo: {} | any
}

export const DEFAULT_USER_STATE: LoginUser = {
    userInfo: {}
};

export default function reducer(state = DEFAULT_USER_STATE, action) {
    switch (action.type) {
       
        case USER_GET_INFO:
            return { ...state, userInfo: action.payload};

       
        


        default:
            return state;
    }
    
}