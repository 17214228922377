
import {CHAT_REQUEST_LIST,SELECTED_CHAT_RQUEST,CHAT_DATA_LIST,SELECTED_CHATID} from '../action/chat.action';

export interface Chat {
    chatRequestList: Array<any>,
    chatDataList: Array<any>,
    selectedChatReuest: {} | any,
    selectedChatId: '' | any
}

export const DEFAULT_CHAT_STATE: Chat = {
    selectedChatId: '',
    selectedChatReuest: {},
    chatRequestList: [],
    chatDataList: [],
};

export default function reducer(state = DEFAULT_CHAT_STATE, action) {
    switch (action.type) {
       
        case CHAT_REQUEST_LIST:
            return { ...state, chatRequestList: action.payload};
        case CHAT_DATA_LIST:
                return { ...state, chatDataList: action.payload};
        case SELECTED_CHAT_RQUEST:
                return { ...state, selectedChatReuest: action.payload};
        case SELECTED_CHATID:
        return { ...state, selectedChatId: action.payload};


        default:
            return state;
    }
    
}