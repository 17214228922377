
import {STAFF_LIST,SELECTED_STAFF } from '../action/staff.action';

export interface Staff {
    staffList: Array<any>,
    selectedStaff: {} | any
}

export const DEFAULT_STAFF_STATE: Staff = {
    selectedStaff: {},
    staffList: [],
};

export default function reducer(state = DEFAULT_STAFF_STATE, action) {
    switch (action.type) {
       
        case STAFF_LIST:
            return { ...state, staffList: action.payload};

        case SELECTED_STAFF:
                return { ...state, selectedStaff: action.payload};
        


        default:
            return state;
    }
    
}