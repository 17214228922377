
import {AGENCY_LIST,SELECTED_AGENCY } from '../action/agency.action';

export interface Agency {
    agencyList: Array<any>,
    selectedAgency: {} | any
}

export const DEFAULT_AGENCY_STATE: Agency = {
    selectedAgency: {},
    agencyList: [],
};

export default function reducer(state = DEFAULT_AGENCY_STATE, action) {
    switch (action.type) {
       
        case AGENCY_LIST:
            return { ...state, agencyList: action.payload};

        case SELECTED_AGENCY:
                return { ...state, selectedAgency: action.payload};
        


        default:
            return state;
    }
    
}